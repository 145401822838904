import { Box, Typography } from "@mui/material";
import React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/Layout";
import bricks from "../images/connect/bricks.svg";
import { MetaData } from "../components/common/meta";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/style.css";

const postStyle = {
  maxWidth: "1000px",
  mx: "auto",
  py: { xs: 8, md: 15 },
};

const Photography = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData
        title="Photography and Videography Consent"
        location={location}
        type="website"
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: 0,
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <Box component="div" className="postStyle" sx={postStyle}>
          <Box
            component="div"
            sx={{
              maxWidth: 750,
              mx: "auto",
              mt: 4,
              mb: 6,
              textAlign: "center",
            }}
          >
            <Typography variant="h1">
              Photography and Videography{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                Consent
              </Box>
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              backgroundColor: "#091A3D",
              border: "1px solid #4B608D",
              px: { xs: 3, md: 5 },
              py: { xs: 3, md: 6 },
            }}
          >
            <Box component="section">
              <Typography sx={{ mb: 3 }}>
                You irrevocably grant to the Jump Crypto Services, LLC and its
                affiliates (collectively, the “Hosts”) the rights to record,
                photograph, or otherwise use, reproduce and edit your voice,
                statements, appearance, likeness, photograph, name, and/or
                company name on audio recording, video recording, digital
                format, or still photography created from or associated with
                your appearance (your “Appearance”) taking place at The Pit.
              </Typography>
              <Typography sx={{ mb: 3 }}>
                You irrevocably grant to the Hosts the rights to use, exhibit,
                distribute, and/or transmit your Appearance at the Hosts’ sole
                discretion, including in advertising, marketing, and/or
                promotion in connection therewith. No compensation, monetary or
                otherwise, will be payable to you at any time in connection with
                the subject matter of this Consent.
              </Typography>
              <Typography>
                You consent, waive, and release liability (including, but not
                limited to, claims for copyright infringement, defamation, or
                invasion of the rights of privacy or publicity), allowing the
                Hosts to publicize and commercially use your likeness and other
                personal characteristics and private information as set out
                above.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <StaticImage
        src="../images/index/lines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "80px",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default Photography;
